<template>
  <el-card class="book-header">
    <el-form :model="data" label-width="120px" class="form" size="mini">
        <el-form-item label="插入位置">
          <el-select v-model="data.content.local">
            <el-option label="全文开头" value="start"></el-option>
            <el-option label="一级标题前" value="chapter_1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否在分栏内">
          <el-checkbox v-model="data.content.is_in_column"></el-checkbox>
        </el-form-item>
      </el-form>
      <div class="editorcontainer">
        <FbdEditor
      v-model="data.content.code_template"
      :options="{ height: 150 }"
    />
      </div>

  </el-card>
</template>

<script>
import editor from 'hexin-ckeditor-doc-vue/dist/editor.umd.js';
import 'hexin-ckeditor-doc-vue/dist/editor.css';
const FbdEditor = editor.FbdEditor;

export default {
  components: {
    FbdEditor
  },
  props: {
    styleData: {
      type: Object,
      required: true
    }
  },
  created () {
    this.init();
  },
  data () {
    return {
      data: {
        content: {
          code_template: '',
          local: '',
          is_in_column: false
        }
      }
    };
  },
  destroyed () {
    this.removeComparePic();
  },
  watch: {

  },
  computed: {

  },
  methods: {
    init () {
      if (this.styleData.components[0].ext_cfg) {
        this.data = this.styleData.components[0].ext_cfg;
      } else {
        this.$set(this.styleData.components[0], 'ext_cfg', this.data);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.editorcontainer{
  margin-left: 50px;
}
</style>
